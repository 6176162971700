import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Icon from '@/components/icons';
import Tooltipped from '@/components/Tooltipped';

const Skills: React.FC = () => {
  const { skills } = useStaticQuery(graphql`
    query {
      skills {
        key
        categories {
          title
          items {
            items {
              items {
                title
              }
              title
            }
            title
            icon
            description
          }
        }
      }
    }
  `);

  return (
    <>
      {skills.categories.map((skillCat) => (
        <dialog className="subwdw" key={skillCat.title}>
          <header className="subwdw-head d-flex">
            <h2 className="subwdw-title">{skillCat.title}</h2>
          </header>
          <div className="subwdw-body">
            {skillCat.items && (
              <ul className="list list--icon">
                {skillCat.items.map((skill) => {
                  const SkillIcon = Icon[skill.icon];
                  return (
                    <li className="skill" key={skill.title}>
                      <header className="skill-head">
                        {SkillIcon && <SkillIcon className="icon" />}
                        <div className="fw-light">{skill.title}</div>
                      </header>
                      {skillCat.items && (
                        <ul className="badge-list">
                          {skill.items.map((subskill) => (
                            <li
                              className="badge-list-item"
                              key={subskill.title}
                            >
                              {subskill.items && subskill.items.length > 0 ? (
                                <Tooltipped
                                  Hovered={() => (
                                    <ul>
                                      {subskill.items.map((i) => (
                                        <li className="ws-nowrap">
                                          - {i.title}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                  clickEqualsHover
                                  toggleable={false}
                                  clickedTimeout={2000}
                                >
                                  <div className="badge active">
                                    {subskill.title}
                                  </div>
                                </Tooltipped>
                              ) : (
                                <div className="badge">{subskill.title}</div>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </dialog>
      ))}
    </>
  );
};

export default Skills;
