import React from 'react';

import Icon from '@/components/icons';
import Skills from '@/components/Skills';
import ExperienceAndEducation from '@/components/ExperienceAndEducation';

const IconHeart = Icon.heart;

const AppWindow: React.FC = () => (
  <dialog className="wdw" open>
    <header className="wdw-head py-2">
      <div>
        <h2 className="h-capitalized mb-0">Résumé</h2>
      </div>
      <div>
        <nav className="wdw-nav">
          {false && <IconHeart className="wdw-nav__icon" />}
        </nav>
      </div>
    </header>
    <div className="wdw-body">
      <ExperienceAndEducation />
      <Skills />
    </div>
  </dialog>
);

export default AppWindow;
