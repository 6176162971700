import CACIcon from '@/static/svg/icons/cac.svg';
import ChatIcon from '@/static/svg/icons/chat.svg';
import CodingIcon from '@/static/svg/icons/coding.svg';
import CreativityIcon from '@/static/svg/icons/creativity.svg';
import DatabaseIcon from '@/static/svg/icons/database.svg';
import DesignIcon from '@/static/svg/icons/design.svg';
import DesigningIcon from '@/static/svg/icons/designing.svg';
import ExpandIcon from '@/static/svg/icons/expand.svg';
import FlagIcon from '@/static/svg/icons/flag.svg';
import FlowerIcon from '@/static/svg/icons/flower.svg';
import HeartIcon from '@/static/svg/icons/heart.svg';
import IdeasIcon from '@/static/svg/icons/ideas.svg';
import LinuxIcon from '@/static/svg/icons/linux.svg';
import PuzzleIcon from '@/static/svg/icons/puzzle.svg';
import RotationIcon from '@/static/svg/icons/rotation.svg';
import SettingsIcon from '@/static/svg/icons/settings.svg';
import StructureIcon from '@/static/svg/icons/structure.svg';
import TeamIcon from '@/static/svg/icons/team.svg';
import TimeManagementIcon from '@/static/svg/icons/time-management.svg';
import ToolsIcon from '@/static/svg/icons/tools.svg';
import WorkEthicIcon from '@/static/svg/icons/work-ethic.svg';
import WorkingIcon from '@/static/svg/icons/working.svg';
import GroupIcon from '@/static/svg/icons/group.svg';
import CollaborationIcon from '@/static/svg/icons/collaboration.svg';

const Icon = {
  cac: CACIcon,
  chat: ChatIcon,
  coding: CodingIcon,
  creativity: CreativityIcon,
  database: DatabaseIcon,
  design: DesignIcon,
  designing: DesigningIcon,
  expand: ExpandIcon,
  flag: FlagIcon,
  flower: FlowerIcon,
  heart: HeartIcon,
  ideas: IdeasIcon,
  linux: LinuxIcon,
  puzzle: PuzzleIcon,
  rotation: RotationIcon,
  settings: SettingsIcon,
  structure: StructureIcon,
  team: TeamIcon,
  'time-management': TimeManagementIcon,
  tools: ToolsIcon,
  'work-ethic': WorkEthicIcon,
  working: WorkingIcon,
  collaboration: CollaborationIcon,
  group: GroupIcon,
};

export default Icon;
