import React from 'react';

import userAvatar from '@/static/images/user-avatar.jpg';
import Ct from '@/classes/Ct';

const UserCard: React.FC = () => (
  <dialog className="wdw user-card" open>
    <div className="editor">
      <pre className="editor__lines">
        <code className="editor-line-filler first" />
        <code>
          <span className="editor-line">
            {'fka := '}
            <span className="struct">
              Sorcerer<span className="bracket">{'{'}</span>
            </span>
          </span>
        </code>
        <code>
          <span className="editor-line">
            {'  '}
            <span className="struct-field">name</span>
            {':   '}
            <span className="string">&quot;Fredrik Kallevik&quot;</span>,
          </span>
        </code>
        <code>
          <span className="editor-line">
            {'  '}
            <span className="struct-field">title</span>
            {':  '}
            <span className="string">&quot;Software Engineer&quot;</span>,
          </span>
        </code>
        <code>
          <span className="editor-line">
            {'  '}
            <span className="struct-field">level</span>
            {':  '}
            <span className="int">10000</span>,{' '}
            <span className="comment">&#47;&#47; hours of work</span>
          </span>
        </code>
        <code>
          <span className="editor-line">
            {'  '}
            <span className="struct-field">origin</span>
            {': '}
            <span className="string">&quot;Norway&quot;</span>,
          </span>
        </code>
        <code>
          <span className="editor-line active">
            <span className="bracket cursor">{'}'}</span>
          </span>
        </code>
        <code className="editor-line-filler" />
      </pre>
    </div>
    <div className="user-card__body">
      <img
        src={userAvatar}
        className="user-card__user-avatar"
        alt="User avatar"
      />
      <h2 className="font-weight-bold d-block mb-1 text-md">
        Hi! I&apos;m Fredrik.
      </h2>
      <p className="bio-text mb-2">
        I practice the art of conjuring the spirits of the computer.
      </p>
      <p className="bio-text mb-2">
        Striving to do work that is <em className="font-weight-bold">good</em>{' '}
        and <em className="font-weight-bold">useful</em>.
      </p>
      <div>
        <p className="bio-text mb-1 text-muted font-weight-bold">
          Get in touch
        </p>
        {Ct.getE()}
        {Ct.getP()}
      </div>
    </div>
  </dialog>
);

export default UserCard;
