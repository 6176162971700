import Wdw from '@/classes/Wdw';

export default class Workspace {
  wdws: Array<Wdw>;

  setWdws(wdws) {
    const newWdws = [];
    wdws.forEach((w) => {
      newWdws.push(new Wdw(w.id, w.Comp));
    });
    this.wdws = newWdws;
  }

  constructor(wdws: Array<Wdw>) {
    this.setWdws(wdws);
  }
}
