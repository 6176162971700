/* eslint-disable */
import React from 'react';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import Tooltipped from '@/components/Tooltipped';
import * as clipboard from 'clipboard-polyfill/text';
import SvgE from '@/static/svg/ct_e.svg';
import SvgP from '@/static/svg/ct_p.svg';

export default class Ct {
  static E = 'U2FsdGVkX19p7v/CDRIOdDuvKMP5Y/Rd3TZpRVzYUGfK7lAGbQfRoRKUMB+n76ZI';
  static P = 'U2FsdGVkX1/I9IYf3GjP+Cf8CV8mY4OocXxGA2X5Jcw=';

  static getE() {
    return (
      <Tooltipped
        Hovered={() => <span>Click to copy</span>}
        Clicked={() => <span>Copied email</span>}
        toggleable={false}
        clickedTimeout={2000}
        className="mb-1"
      >
        <SvgE
          className="ct_e"
          onClick={() => {
            Ct.copyCt(Ct.E);
          }}
        />
      </Tooltipped>
    );
  }

  static getP() {
    return (
      <Tooltipped
        Hovered={() => <>Click to copy</>}
        Clicked={() => <>Copied number</>}
        toggleable={false}
        clickedTimeout={2000}
        className="mb-1"
      >
        <SvgP
          className="ct_p"
          onClick={() => {
            Ct.copyCt(Ct.P);
          }}
        />
      </Tooltipped>
    );
  }

  static decryptWithAES(ciphertext, passphrase) {
    const bytes = AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(Utf8);
    return originalText;
  }

  static copyCt(value) {
    Ct.copyToClipboard(Ct.decryptWithAES(value, 'supersecret'));
  }

  static copyToClipboard(value) {
    clipboard.writeText(value).then(
      function () {},
      function () {
        console.log('copy error!');
      },
    );
  }
}
