import React from 'react';

export default class Wdw {
  id: number;

  Comp: React.FC;

  constructor(id: number, Comp: React.FC) {
    this.id = id;
    this.Comp = Comp;
  }
}
