import React from 'react';

const ExperienceAndEducation: React.FC = () => (
  <>
    <dialog className="subwdw" open>
      <header className="subwdw-head d-flex">
        <h2 className="subwdw-title">Work Experience</h2>
      </header>
      <div className="subwdw-body">
        <ol className="list list--timeline">
          <li>
            <div className="card card--dark">
              <div className="card__header">
                <time className="text-white">April 2021 - Now</time>
              </div>
              <div className="card__body">
                <div className="text-white">Developer and Graphic Designer</div>
              </div>
              <div className="card__footer">
                <div>
                  <a
                    href="https://dokkodo.no/"
                    target="_blank"
                    className="font-weight-bold text-primary"
                    rel="noreferrer noopener"
                  >
                    Dokkodo AS
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="card">
              <div className="card__header">
                <time>2020 - May 2021</time>
              </div>
              <div className="card__body">
                <div>Web / App Developer</div>
              </div>
              <div className="card__footer">
                <div>Serit iDrift</div>
              </div>
            </div>
          </li>
          <li>
            <div className="card">
              <div className="card__header">
                <time>2015 - 2020</time>
              </div>
              <div className="card__body">
                <div className="">Web Developer</div>
              </div>
              <div className="card__footer">
                <div>iDrift Web</div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </dialog>
    <dialog className="subwdw" open>
      <header className="subwdw-head d-flex">
        <h2 className="subwdw-title">Education</h2>
      </header>
      <div className="subwdw-body">
        <ol className="list list--timeline">
          <li>
            <div className="card">
              <div className="card__header">
                <time>2012 - 2015</time>
              </div>
              <div className="card__body">
                <div>Bachelor of Engineering</div>
                <div>Computer Science</div>
              </div>
              <div className="card__footer">
                <div>University of Stavanger</div>
              </div>
            </div>
          </li>
          <li>
            <div className="card">
              <div className="card__header">
                <time>2009 - 2011</time>
              </div>
              <div className="card__body">
                <div>Media and Communication</div>
              </div>
              <div className="card__footer">
                <div>Vardafjell VGS.</div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </dialog>
  </>
);

export default ExperienceAndEducation;
