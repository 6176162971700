/* eslint-disable */
import React from 'react';

import Logo from '@/static/svg/logo-mobile.svg';

const navItems = [
  {
    path: '/',
    title: 'Résumé',
  },
  {
    path: '/about/',
    title: 'About',
  },
];

const AppHeader: React.FC = ({ pathname }) => {
  return (
    <header className="app-header">
      <a href="/" className="d-flex">
        <Logo className="app-logo" />
      </a>
      <nav className="header-nav">
        {navItems.map((item) => (
          <a
            href={item.path}
            className={`${pathname === item.path ? 'active' : ''}`}
            key={item.path}
          >
            {item.title}
          </a>
        ))}
      </nav>
    </header>
  );
};

export default AppHeader;
