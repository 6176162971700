/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { PageProps } from 'gatsby';

const Tooltipped: React.FC<PageProps> = ({
  Hovered = null,
  Clicked = null,
  clickedTimeout = null,
  clickEqualsHover = false,
  toggleable = true,
  className,
  children,
}) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  Clicked = Clicked ?? (clickEqualsHover ? Hovered : null);

  const toggleClicked = () =>
    setClicked(
      toggleable ? !clicked : Clicked || clickEqualsHover ? true : false,
    );

  useEffect(() => {
    let clickedTimer = setTimeout(() => {
      if (Clicked !== null) {
        setClicked(false);
      }
    }, clickedTimeout);

    return () => {
      clearTimeout(clickedTimer);
    };
  }, [clicked]);

  return (
    <div className={`tooltipper ${className ? className : ''}`}>
      <div
        className="tooltipped"
        onPointerEnter={toggleHover}
        onPointerLeave={toggleHover}
        onClick={toggleClicked}
      >
        {children}
      </div>
      <div
        aria-live="assertive"
        role="tooltip"
        className={`tooltip ${
          hovered || (clickEqualsHover && clicked) ? 'hovered' : ''
        } ${Clicked && clicked && !clickEqualsHover ? 'clicked' : ''}`}
      >
        {clicked && (clickEqualsHover || Clicked) && <Clicked />}
        {!clicked && hovered && <Hovered />}
      </div>
    </div>
  );
};

export default Tooltipped;
