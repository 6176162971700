import React, { useState } from 'react';

import Workspace from '@/classes/Workspace';

import UserCard from '@/components/UserCard';
import AppWindow from '@/components/AppWindow';

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [ws1, setWs1] = useState(
    new Workspace([
      {
        id: 'uc',
        Comp: UserCard,
      },
      {
        id: 'aw',
        Comp: AppWindow,
      },
    ]),
  );

  const appContext = {
    ws1,
    setWs1,
  };

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

export const { AppContextConsumer } = AppContext;
